import React from "react";

import {
  Box,
  Button,
  PrismicLink,
  RichText,
  SectionWrapper
} from "src/components";

const Intro = ({ description, buttonText, buttonLink }) => (
  <>
    <SectionWrapper id="main" maxWidth={0} py={[8, 9]} bg="bg.default">
      {description && (
        <RichText fontSize={[1, 2]} color="text.body">
          {description}
        </RichText>
      )}
      {buttonText && buttonLink && (
        <Box mt={5}>
          <PrismicLink link={buttonLink}>
            <Button children={buttonText} variant="primary" />
          </PrismicLink>
        </Box>
      )}
    </SectionWrapper>
  </>
);

export default Intro;
