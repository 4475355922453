import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";
import { PageHeaderV2, SEO } from "src/components";

import Intro from "./_components/_intro";
import Other from "./_components/_other";

import Collage from "../../_components/_collageSlice";

const SponsorPage = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        getInvolved: prismicGetInvolved {
          dataString
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.getInvolved.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={"Sponsor"}
            description={
              page.sponsor_description &&
              RichText.asText(page.sponsor_description)
            }
          />
          <PageHeaderV2
            heading={
              page.sponsor_header && RichText.asText(page.sponsor_header)
            }
            image={page.sponsor_image}
            color="brand.altPrimary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          <Intro
            subtitle={
              page.sponsor_header && RichText.asText(page.sponsor_header)
            }
            image={page.sponsor_image}
            image2={page.sponsor_image_2}
            image3={page.sponsor_image_3}
            description={
              page.sponsor_description &&
              RichText.render(
                page.sponsor_description,
                linkResolver,
                serializer
              )
            }
            buttonText={page.sponsor_button_text}
            buttonLink={page.sponsor_button_link}
          />
          {page.sponsor_collage &&
            page.sponsor_collage[0] &&
            page.sponsor_collage[0].image &&
            page.sponsor_collage[0].image.url && (
              <Collage items={page.sponsor_collage} />
            )}
          <Other otherWaysToSponsor={page.other_ways_to_sponsor} />
        </>
      );
    }}
  />
);

export default SponsorPage;
