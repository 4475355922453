import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  H2,
  Box,
  Button,
  PrismicLink,
  RichText,
  SectionWrapper
} from "src/components";

const Other = ({ otherWaysToSponsor }) => (
  <>
    {otherWaysToSponsor &&
      otherWaysToSponsor[0] &&
      otherWaysToSponsor[0].program_title &&
      otherWaysToSponsor[0].program_title[0] && (
        <>
          <SectionWrapper
            id="other"
            maxWidth={0}
            py={[8, 9, 10]}
            bg="bg.wash"
            css={`
              border-bottom: 1px solid ${props => props.theme.colors.bg.alt};
            `}
          >
            <H2
              children={PrismicRichText.asText(
                otherWaysToSponsor[0].program_title
              )}
            />
            {otherWaysToSponsor[0].program_description &&
              otherWaysToSponsor[0].program_description[0] && (
                <Box mt={3}>
                  <RichText
                    children={PrismicRichText.render(
                      otherWaysToSponsor[0].program_description,
                      linkResolver,
                      serializer
                    )}
                    fontSize={[1, 2]}
                    color="text.body"
                  />
                </Box>
              )}
            {otherWaysToSponsor[0].button_text &&
              otherWaysToSponsor[0].button_link && (
                <Box mt={5}>
                  <Button
                    children={otherWaysToSponsor[0].button_text}
                    as={PrismicLink}
                    link={otherWaysToSponsor[0].button_link}
                    variant="defaultAlt"
                  />
                </Box>
              )}
          </SectionWrapper>
        </>
      )}
  </>
);

export default Other;
